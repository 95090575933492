class QuickshopVariantSelector extends HTMLElement {
  constructor() {
      super();
      this.blockID = this.getAttribute('data-block'); // Block ID for the variant container
      this.initEventListeners();
  }

  initEventListeners() {
      // Attach a change event listener to this component for event delegation,
      // specifically listening for changes to variant radio inputs.
      this.addEventListener('change', (event) => {
          if (event.target.matches('.product-variant-radio')) {
              console.log('Variant changed:', event.target.value); // Debugging log

              // Process the variant change
              this.onVariantChange(event.target);
          }
      });
  }

  onVariantChange(selectedRadio) {
      // Update the checked attribute for all radio inputs and set the selected one
      this.updateCheckedState(selectedRadio);

      // Extract the selected variant ID from the radio input
      const selectedVariantId = selectedRadio.value;
      const blockId = this.blockID;
      console.log(`Updating to variant ID: ${selectedVariantId} for ${blockId}`); // Debugging log

      // Update the hidden input and variant classes
      this.updateVariantInput(selectedVariantId);
      this.updateVariantClasses(selectedVariantId);
  }

  updateCheckedState(selectedRadio) {
    const radios = this.querySelectorAll('.product-variant-radio');
    radios.forEach(radio => {
        radio.checked = false; // Clear checked state for all
        radio.removeAttribute('checked'); // Remove the checked attribute
        radio.setAttribute('aria-checked', 'false');
    });
    selectedRadio.checked = true; // Check the selected radio
    selectedRadio.setAttribute('checked', 'checked'); // Add the checked attribute
    selectedRadio.setAttribute('aria-checked', 'true');
  }

  updateVariantInput(selectedVariantId) {
      const variantInput = this.closest('.product-card').querySelector('.product-variant-id');
      variantInput.value = selectedVariantId; // Update the hidden input's value
  }

  updateVariantClasses(selectedVariantId) {
      const blockId = this.blockID;
      const labels = this.querySelectorAll('label');
      labels.forEach(label => {
          label.classList.remove('border-black', 'border-b'); // Remove classes from all labels
          if (label.htmlFor === `variant-${selectedVariantId}-${blockId}`) {
              label.classList.add('border-black', 'border-b'); // Add to the selected label
          }
      });
  }
}

customElements.define('quickshop-variant-selector', QuickshopVariantSelector);
